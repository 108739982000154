// Composables
import { createRouter, createWebHistory } from 'vue-router'
import MainPage from "@/components/MainPage.vue"
import ErrorPage from "../components/main/ErrorPage.vue"

import store from "@/Store/index"
import dbhelper from '@/plugins/dbHelper'

import { useFavicon } from '@vueuse/core'

// ACHTUNG Nur den Kunden einkommentieren, der ausgeliefert wird !
//         sonst werden unnötige Dateien mit ausliefert

async function loadSettings() {
  store.state.ZahlungsartDisplayName = "Zahlungsart";

  var LKundeDefiniert = false;  // Kontrollvariable ob mind. 1 Kunde einkommentiert ist
  // TODO Default Einstellung voranstellen (z.B. Diestelmann IT GmbH Demo)
  //      Bei den Kunden nur die "geänderten Werte" anpassen

  /*  if((!LKundeDefiniert) &&(store.state.Kunde=='Diestelmann IT GmbH'))
   {
     LKundeDefiniert = true;
     store.state.AppTitle = "Reservierungsportal Standrohr";
     store.state.AppTitleShort = 'RP Standrohr';
 
     store.state.VerwaltungEinschalten = false;
     store.state.ToolbarAnzeigen = true;
     store.state.Bankenmodul = true;
     store.state.FelderNichtAnzeigen = [
       "AGB"
     ];
 
     store.state.VertragsPflichtfelder = [  
         "Geraeteklasse",
         "Bauform",
         "Nachname",
         "Vorname",
         "Strasse",
         "Hausnummer",
         "Plz",
         "Ort",
         "EMail",
         "Kontoinhaber",
         "Iban",
         "BIC",
         "Bankinstitut",
         "ServicePoint",
         "StueckHydrantenschluessel"
       ];
 
     store.state.ImpressumVorhanden = true;
     store.state.ImpressumPath="https://itsds.de/index.php/impressum"
 
     var FaviconIcon = useFavicon();
     var FaviconIconpath = await import("../assets/Diestelmann/DF_logo.png");
     FaviconIcon.value = FaviconIconpath.default;
 
     var Logo = await import("../assets/Diestelmann/DF_logo.png");
 
     store.state.Logo = Logo.default;
     store.state.LogoBreite = "150";
     store.state.LogoHoehe = "24";
     store.state.Zahlungsart = "Vorabüberweisung";
     store.state.BezahlItems = [
           "ohne Kaution",
           "Verrechnungsscheck",
           "SEPA Lastschrift",
           "Mastercard",
           "EC Cash",
     ];
 
     store.state.DatenschutzDisplayname = "Datenschutzinfo";
     var Datenschutzpath = "https://itsds.de/index.php/datenschutz";
     store.state.Datenschutzpath = Datenschutzpath;
 
     store.state.NameDisplayName = "Bankvorauswahl";
     store.state.KontoinhaberDisplayName = "Name des Kontoinhabers*";
     store.state.IBANDisplayName = "IBAN*";
     store.state.BICDisplayName = "BIC*";
     store.state.BankinstitutDisplayName = "Bank*";
 
     store.state.KundennummerDisplayName = "Kundennummer (falls bekannt)";
     store.state.FuerPersonDisplayName = "Erstellt für*";
     store.state.FirmaDisplayName = "Firma*";
     store.state.AnredeDisplayName = "Anrede";
     store.state.NachnameDisplayName = "Nachname*";
     store.state.VornameDisplayName = "Vorname*";
     store.state.StrasseDisplayName = "Straße*";
     store.state.HausnummerDisplayName = "Hausnummer*";
     store.state.HausNrErgDisplayName = "Ergänzung";
     store.state.PlzDisplayName = "Postleitzahl*";
     store.state.OrtDisplayName = "Ort*";
     store.state.TelefonDisplayName = "Telefon";
     store.state.Telefon2DisplayName = "Telefon2";
     store.state.MobilDisplayName = "Mobil";
     store.state.EMailDisplayName = "E-Mail*";
     store.state.LandDisplayName = "Land";
 
     store.state.GeraeteklassenDisplayName = "Geräteklasse";
     store.state.BauformDisplayName = "Standrohrtyp*";
     store.state.KonzessionsgebietDisplayName = "Konzessionsgebiet festlegen";
     store.state.EinsatzstelleDisplayName = "Einsatzstelle";
     store.state.VoraussichtlicheDauerDisplayName = "voraussichtliche Mietdauer (in Monaten)";
     store.state.LangzeitmieteDisplayName = "Langzeitmiete";
     store.state.ZahlungsartDisplayName = "Zahlungsart";
     store.state.AusgabeNameDisplayName = "Name";
     store.state.AusgabeVornameDisplayName = "Vorname";
 
     store.state.ServicePointDisplayName = "Service-Point";
     store.state.EinsatzortDisplayName = "Einsatzort";
     store.state.MitAbwasserDisplayName = "Mit Abwasser";
     store.state.EntnahmezweckDisplayName = "Zweck der Entnahme";
     store.state.StueckHydrantenschluesselDisplayName = "Anzahl Hydrantenschlüssel*";
     store.state.BemerkungenDisplayName = "Bemerkungen"
 
     import("../assets/Diestelmann/Diestelmann_KundenConfig.css");
   }
  */

  /*
   if ((!LKundeDefiniert) && (store.state.Kunde == 'SWKampLintfort')) {
     LKundeDefiniert = true;
     store.state.AppTitle = "Reservierungsportal Standrohr";
     store.state.AppTitleShort = 'SP Standrohr';
 
     store.state.VerwaltungEinschalten = false;
     store.state.ToolbarAnzeigen = true;
     store.state.Bankenmodul = true;
     store.state.FelderNichtAnzeigen = [
       "MitAbwasser",
       "Einsatzort",
       "Entnahmezweck",
       "Konzessionsgebiet",
       "Bank",
       "Langzeitmiete"
     ];
 
     store.state.VertragsPflichtfelder = [  
         "Geraeteklasse",
         "Bauform",
         "Nachname",
         "Vorname",
         "Strasse",
         "Hausnummer",
         "Plz",
         "Ort",
         "EMail",
         "Kontoinhaber",
         "Iban",
         "BIC",
         "Bankinstitut",
         "ServicePoint",
         "StueckHydrantenschluessel"
       ];
 
     store.state.ImpressumVorhanden = true;
     store.state.ImpressumPath="https://www.swkl.de/impressum"
 
     var FaviconIcon = useFavicon();
     var FaviconIconpath = await import("../assets/KampLinfort/KampLinfort_logo.jpg");
     FaviconIcon.value = FaviconIconpath.default;
 
     var Logo = await import("@/assets/KampLinfort/KampLinfort_logo.jpg");
 
     store.state.Logo = Logo.default;
     store.state.LogoBreite = "150";
     store.state.LogoHoehe = "24";
     store.state.Zahlungsart = "Vorabüberweisung";
     store.state.BezahlItems = [
       "Vorabüberweisung"
     ];
 
     store.state.AGBDisplayname = "Ergänzende Bestimmung der Stadtwerke Kamp-Lintfort GmbH";
     var AGBpath = "https://www.swkl.de/download/ergaenzende-bestimmungen-zur-verordnung-ueber-allgemeine-bedingungen-fuer-die-versorgung-mit-wasser-abvwasserv";
     store.state.AGBpath = AGBpath;
 
     store.state.DatenschutzDisplayname = "Datenschutzinfo";
     var Datenschutzpath = "https://www.swkl.de/datenschutz-2";
     store.state.Datenschutzpath = Datenschutzpath;
 
     store.state.SonstigesDokumentDisplayname = "AVBWasserV";
     var SonstigesDokumentpath = "https://www.swkl.de/download/verordnung-u%cc%88ber-allgemeine-bedingungen-fu%cc%88r-die-versorgung-mit-wasser-avbwasserv";
     store.state.SonstigesDokumentpath = SonstigesDokumentpath;
 
     var PreisFilepath = "https://www.swkl.de/privatkunden-wasser"
     store.state.PreisFile = PreisFilepath
     var Anleitungpath = await import("../assets/KampLinfort/Anleitung.pdf");
     store.state.AnleitungFile = Anleitungpath.default;
 
     store.state.NameDisplayName = "Bankvorauswahl";
     store.state.KontoinhaberDisplayName = "Name des Kontoinhabers*";
     store.state.IBANDisplayName = "IBAN*";
     store.state.BICDisplayName = "BIC*";
     store.state.BankinstitutDisplayName = "Bank*";
 
     store.state.KundennummerDisplayName = "Kundennummer (falls bekannt)";
     store.state.FuerPersonDisplayName = "Erstellt für*";
     store.state.FirmaDisplayName = "Firma*";
     store.state.AnredeDisplayName = "Anrede";
     store.state.NachnameDisplayName = "Nachname*";
     store.state.VornameDisplayName = "Vorname*";
     store.state.StrasseDisplayName = "Straße*";
     store.state.HausnummerDisplayName = "Hausnummer*";
     store.state.HausNrErgDisplayName = "Ergänzung";
     store.state.PlzDisplayName = "Postleitzahl*";
     store.state.OrtDisplayName = "Ort*";
     store.state.TelefonDisplayName = "Telefon";
     store.state.Telefon2DisplayName = "Telefon2";
     store.state.MobilDisplayName = "Mobil";
     store.state.EMailDisplayName = "E-Mail*";
     store.state.LandDisplayName = "Land";
 
     store.state.GeraeteklassenDisplayName = "Geräteklasse";
     store.state.BauformDisplayName = "Standrohrtyp*";
     store.state.KonzessionsgebietDisplayName = "Konzessionsgebiet festlegen";
     store.state.EinsatzstelleDisplayName = "Einsatzstelle";
     store.state.VoraussichtlicheDauerDisplayName = "voraussichtliche Mietdauer (in Monaten)";
     store.state.LangzeitmieteDisplayName = "Langzeitmiete";
     store.state.ZahlungsartDisplayName = "Zahlungsart";
     store.state.AusgabeNameDisplayName = "Name";
     store.state.AusgabeVornameDisplayName = "Vorname";
 
     store.state.ServicePointDisplayName = "Service-Point";
     store.state.EinsatzortDisplayName = "Einsatzort";
     store.state.MitAbwasserDisplayName = "Mit Abwasser";
     store.state.EntnahmezweckDisplayName = "Zweck der Entnahme";
     store.state.StueckHydrantenschluesselDisplayName = "Anzahl Hydrantenschlüssel*";
     store.state.BemerkungenDisplayName = "Bemerkungen"
 
     import("@/assets/KampLinfort/KampLinfort_KundenConfig.css");
   } */

  /*   if ((!LKundeDefiniert) && (store.state.Kunde == 'BochumNetz')) {
      LKundeDefiniert = true;
        store.state.AppTitle = "Reservierungsportal";
        store.state.AppTitleShort = 'Reservierung';
    
      // var FaviconIcon = useFavicon();
      //  var FaviconIconpath = await import("../assets/KampLinfort/KampLinfort_logo.jpg");
      //  FaviconIcon.value = FaviconIconpath.default;
    
      //  var Logo = await import("@/assets/KampLinfort/KampLinfort_logo.jpg");
    
      //  store.state.Logo = Logo.default;
      //  store.state.LogoBreite = "150";
      //  store.state.LogoHoehe = "24";
        store.state.Zahlungsart = "Vorabüberweisung";
        store.state.BezahlItems = [
          "Vorabüberweisung"
        ];
    
      //  store.state.AGBDisplayname = "Ergänzende Bestimmung der Stadtwerke Kamp-Lintfort GmbH";
      //  var AGBpath = "https://www.swkl.de/download/ergaenzende-bestimmungen-zur-verordnung-ueber-allgemeine-bedingungen-fuer-die-versorgung-mit-wasser-abvwasserv";
      //  store.state.AGBpath = AGBpath;
    
      //  store.state.DatenschutzDisplayname = "Datenschutzinfo";
      //  var Datenschutzpath = "https://www.swkl.de/datenschutz-2";
      //  store.state.Datenschutzpath = Datenschutzpath;
    
      //  store.state.SonstigesDokumentDisplayname = "AVBWasserV";
      //  var SonstigesDokumentpath = "https://www.swkl.de/download/verordnung-u%cc%88ber-allgemeine-bedingungen-fu%cc%88r-die-versorgung-mit-wasser-avbwasserv";
      //  store.state.SonstigesDokumentpath = SonstigesDokumentpath; 
    
      // var PreisFilepath = await import("../assets/KampLinfort/2024-01-18_Preisblatt_Standrohre_und_Bauwasser.pdf");
      // store.state.PreisFile = PreisFilepath.default;
      // var Anleitungpath = await import("../assets/KampLinfort/Anleitung.pdf");
      //  store.state.AnleitungFile = Anleitungpath.default;
    
        store.state.NameDisplayName = "Bankvorauswahl";
        store.state.KontoinhaberDisplayName = "Name des Kontoinhabers*";
        store.state.IBANDisplayName = "IBAN*";
        store.state.BICDisplayName = "BIC*";
        store.state.BankinstitutDisplayName  ="Bank*";
    
        store.state.KundennummerDisplayName="Kundennummer (falls bekannt)";
        store.state.FuerPersonDisplayName="Erstellt für*";
        store.state.FirmaDisplayName="Firma*";
        store.state.AnredeDisplayName="Anrede";
        store.state.NachnameDisplayName="Nachname*";
        store.state.VornameDisplayName="Vorname*";
        store.state.StrasseDisplayName="Straße*";
        store.state.HausnummerDisplayName="Hausnummer*";
        store.state.HausNrErgDisplayName="Ergänzung";
        store.state.PlzDisplayName="Postleitzahl*";
        store.state.OrtDisplayName="Ort*";
        store.state.TelefonDisplayName="Telefon";
        store.state.Telefon2DisplayName="Telefon2";
        store.state.MobilDisplayName="Mobil";
        store.state.EMailDisplayName="E-Mail*";
        store.state.LandDisplayName="Land";
    
        store.state.GeraeteklassenDisplayName="Geräteklasse";
        store.state.BauformDisplayName="Standrohrtyp*";
        store.state.KonzessionsgebietDisplayName = "Konzessionsgebiet festlegen";
        store.state.EinsatzstelleDisplayName="Einsatzstelle";
        store.state.VoraussichtlicheDauerDisplayName="voraussichtliche Mietdauer";
        store.state.LangzeitmieteDisplayName = "Langzeitmiete";
        store.state.ZahlungsarDisplayName = "Zahlungsart";
        store.state.AusgabeNameDisplayName = "Name";
        store.state.AusgabeVornameDisplayName = "Vorname";
        
        store.state.ServicePointDisplayName = "Service-Point";
        store.state.EinsatzortDisplayName = "Einsatzort";
        store.state.MitAbwasserDisplayName = "Mit Abwasser";
        store.state.EntnahmezweckDisplayName = "Zweck der Entnahme";
        store.state.StueckHydrantenschluesselDisplayName="Anzahl Hydrantenschlüssel";
        store.state.BemerkungenDisplayName="Bemerkungen"
    
        
        import("@/assets//Bochum/BochumNetz_KundenConfig.css");
      }
   */
  /*if ((!LKundeDefiniert) && (store.state.Kunde == 'WasserwerkePaderborn')) {
    LKundeDefiniert = true;

    store.state.VerwaltungEinschalten = true;
    store.state.ToolbarAnzeigen = true;
    store.state.Bankenmodul = false;
    store.state.FelderNichtAnzeigen = [
    "Langzeitmiete",
    "Einsatzort",
    "MitAbwasser",
    "Konzessionsgebiet",
    "SonstigesDokument1",
    "SonstigeButtons",
    "Impressum"
      
    ];

    store.state.VertragsPflichtfelder = [
      "Bauform",
      "Nachname",
      "Vorname",
      "Strasse",
      "Hausnummer",
      "Plz",
      "Ort",
      "EMail",
      "Kontoinhaber",
      "Iban",
      "BIC",
      "Bankinstitut",
      "ServicePoint"
    ];

    store.state.ImpressumVorhanden = false;

    store.state.AppTitle = "Kundenportal";
    store.state.AppTitleShort = 'Kundenportal';
    document.title = store.state.AppTitle;
    var Logo = await import("@/assets/WWPaderborn/WWP_logo.png");
    store.state.Logo = Logo.default;

    var FaviconIcon = useFavicon();
    var FaviconIconpath = await import("../assets/WWPaderborn/favion.png");
    FaviconIcon.value = FaviconIconpath.default;

    store.state.LogoBreite = "150";
    store.state.LogoHoehe = "24";
    store.state.BezahlItems = [
      "Vorabüberweisung",
      "ohne Kaution",
      "Verrechnungsscheck",
      "SEPA Lastschrift",
      "Mastercard",
      "EC Cash",
    ];

    store.state.AGBDisplayname = "AGB";
    var AGBpath = await import("@/assets/WWPaderborn/AGB.pdf");
    store.state.AGBpath = AGBpath.default;

    store.state.DatenschutzDisplayname = "Datenschutzinfo";
    var Datenschutzpath = await import("@/assets/WWPaderborn/Datenschutzerklaerung.pdf");
    store.state.Datenschutzpath = Datenschutzpath.default;

    store.state.CookieBannerEingeschaltet = false;
    store.state.DokumentArten = ["Alle", "Vollmacht", "Gewerbenachweise/HR-Auszüge", "Prüfung: Standrohrrückgabe", "Vertrag", "Foto Standrohr", "Foto Zähler", "Prüfung: Werkstattfreigabe", "Rechnungen", "Sonstige Dokumente"];
    store.state.MaxFileSize = 1000000;

    store.state.NameDisplayName = "Bankvorauswahl";
    store.state.KontoinhaberDisplayName = "Name des Kontoinhabers";
    store.state.IBANDisplayName = "IBAN";
    store.state.BICDisplayName = "BIC";
    store.state.BankinstitutDisplayName = "Bankinstitut";

    store.state.KundennummerDisplayName = "Kundennummer (falls bekannt)";
    store.state.FuerPersonDisplayName = "Erstellt für";
    store.state.FirmaDisplayName = "Firma";
    store.state.AnredeDisplayName = "Anrede";
    store.state.NachnameDisplayName = "Nachname";
    store.state.VornameDisplayName = "Vorname";
    store.state.StrasseDisplayName = "Straße";
    store.state.HausnummerDisplayName = "Hausnummer";
    store.state.HausNrErgDisplayName = "Ergänzung";
    store.state.PlzDisplayName = "Postleitzahl";
    store.state.OrtDisplayName = "Ort";
    store.state.TelefonDisplayName = "Telefon";
    store.state.Telefon2DisplayName = "Telefon2";
    store.state.MobilDisplayName = "Mobil";
    store.state.EMailDisplayName = "E-Mail";
    store.state.LandDisplayName = "Land";

    store.state.GeraeteklassenDisplayName = "Geräteklasse";
    store.state.BauformDisplayName = "Wählen sie den benötigten Standrohrtyp";
    store.state.KonzessionsgebietDisplayName = "Konzessionsgebiet festlegen";
    store.state.EinsatzstelleDisplayName = "Einsatzstelle";
    store.state.VoraussichtlicheDauerDisplayName = "Voraussichtliche Dauer";
    store.state.LangzeitmieteDisplayName = "Langzeitmiete";
    store.state.ZahlungsarDisplayName = "Zahlungsart";
    store.state.AusgabeNameDisplayName = "Name";
    store.state.AusgabeVornameDisplayName = "Vorname";

    store.state.ServicePointDisplayName = "Service-Point";
    store.state.EinsatzortDisplayName = "Einsatzort";
    store.state.MitAbwasserDisplayName = "Mit Abwasser";
    store.state.EntnahmezweckDisplayName = "Zweck der Entnahme";
    store.state.StueckHydrantenschluesselDisplayName = "Anzahl Hydrantenschlüssel";
    store.state.BemerkungenDisplayName = "Bemerkungen"

    import("@/assets/WWPaderborn/WWP_KundenConfig.css");
  }
*/
      if ((!LKundeDefiniert) && (store.state.Kunde == 'NEWNetzGmbH')) {
          LKundeDefiniert = true;

          var Logo = await import("@/assets/NEW/NEW_NETZ_logo.svg");
          var FaviconIcon = useFavicon();
          var FaviconIconpath = await import("../assets/NEW/NEW_NETZ_Logo.svg");
          FaviconIcon.value = FaviconIconpath.default;
    
          store.state.VerwaltungEinschalten = false;
          store.state.ToolbarAnzeigen = false;
          store.state.Bankenmodul = true;
          store.state.FelderNichtAnzeigen = [
            "AGB",
            "Datenschutzerklaerung",
            "SonstigesDokument1",
            "SonstigeButtons"
          ];
      
          store.state.VertragsPflichtfelder = [  
            "Geraeteklasse",
            "Bauform",
            "Nachname",
            "Vorname",
            "Strasse",
            "Hausnummer",
            "Plz",
            "Ort",
            "EMail",
            "Kontoinhaber",
            "Iban",
            "BIC",
            "Bankinstitut",
            "ServicePoint"
          ];

          store.state.ImpressumVorhanden = false;
          store.state.AppTitle = "Kundenportal";
          store.state.AppTitleShort = 'Kundenportal'
          document.title = store.state.AppTitle;
          store.state.Logo = Logo.default;
          store.state.LogoBreite = "150";
          store.state.LogoHoehe = "24";
          store.state.CookieBannerEingeschaltet = false
          store.state.Konzessionsgebiet="Mönchengladbach"
          store.state.BezahlItems = [
            "Vorabüberweisung",
            "ohne Kaution",
            "Verrechnungsscheck",
            "SEPA Lastschrift",
            "Mastercard",
            "EC Cash",
          ];
    
        store.state.NameDisplayName = "Bankvorauswahl";
        store.state.KontoinhaberDisplayName = "Name des Kontoinhabers";
        store.state.IBANDisplayName = "IBAN";
        store.state.BICDisplayName = "BIC";
        store.state.BankinstitutDisplayName  ="Bankinstitut";
    
        store.state.KundennummerDisplayName="Kundennummer (falls bekannt)";
        store.state.FuerPersonDisplayName="Erstellt für";
        store.state.FirmaDisplayName="Firma";
        store.state.AnredeDisplayName="Anrede";
        store.state.NachnameDisplayName="Nachname";
        store.state.VornameDisplayName="Vorname";
        store.state.StrasseDisplayName="Straße";
        store.state.HausnummerDisplayName="Hausnummer";
        store.state.HausNrErgDisplayName="Ergänzung";
        store.state.PlzDisplayName="Postleitzahl";
        store.state.OrtDisplayName="Ort";
        store.state.TelefonDisplayName="Telefon";
        store.state.Telefon2DisplayName="Telefon2";
        store.state.MobilDisplayName="Mobil";
        store.state.EMailDisplayName="E-Mail";
        store.state.LandDisplayName="Land";
    
        store.state.GeraeteklassenDisplayName="Geräteklasse";
        store.state.BauformDisplayName="Wählen sie den benötigten Standrohrtyp";
        store.state.KonzessionsgebietDisplayName = "Konzessionsgebiet festlegen";
        store.state.EinsatzstelleDisplayName="Einsatzstelle";
        store.state.VoraussichtlicheDauerDisplayName="Voraussichtliche Dauer";
        store.state.LangzeitmieteDisplayName = "Langzeitmiete";
        store.state.ZahlungsarDisplayName = "Zahlungsart";
        store.state.AusgabeNameDisplayName = "Name";
        store.state.AusgabeVornameDisplayName = "Vorname";
        
        store.state.ServicePointDisplayName = "Service-Point";
        store.state.EinsatzortDisplayName = "Einsatzort";
        store.state.MitAbwasserDisplayName = "Mit Abwasser";
        store.state.EntnahmezweckDisplayName = "Zweck der Entnahme";
        store.state.StueckHydrantenschluesselDisplayName="Anzahl Hydrantenschlüssel";
        store.state.BemerkungenDisplayName="Bemerkungen"
        import("@/assets/NEW/NEW_Netz_KundenConfig.css");
     }  
  
     if (!LKundeDefiniert){
       console.log('FEHLER: Alle Kunden sind auskommentiert!');
     } 
}

const routes = [
  {
    path: '/reservierung/default',
    component: MainPage,
    beforeEnter: async (to, from) => {

      var url = "LoadSettings";
      var response = await dbhelper.loadSettings(url);
      var Kunde = response.Kunde
      store.state.CompanyName = Kunde;
      store.state.Kunde = Kunde.split(/\s+/).join('');
      store.state.VerwaltungEinschalten = response.UserVerwaltung;
      store.state.ToolbarAnzeigen = response.ToolbarAnzeigen;
      store.state.Bankenmodul = response.Bankenmodul;
      store.state.FelderNichtAnzeigen = response.VertragsFelderNichtAnzeigen;
      store.state.VertragsPflichtfelder = response.VertragsPflichtfelder;
      loadSettings();
    }
  },

  {
    path: '/reservierung/ResetPage',
    component: MainPage,
    props: true,
    beforeEnter: async (to, from) => {

      var url = "LoadSettings";
      var response = await dbhelper.loadSettings(url);

      var Kunde = response.Kunde;
      store.state.CompanyName = Kunde;

      store.state.Kunde = Kunde.split(/\s+/).join('');
      loadSettings();
    }
  },

  // route wenn keine andere route passt dann wirdf automatisch die Error Page aufgerufen (route Protection) 
  { path: '/:pathMatch(.*)*', component: ErrorPage }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
